import React, { Component } from "react";
import "./DisplayAdsLargeBanner.css";

export default class DisplayAdsLargeBanner extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <ins
        className="adsbygoogle general_large_banner"
        style={{ display: "inline-block" }}
        data-ad-client="ca-pub-8753358837449417"
        data-ad-slot="3378238506"
      ></ins>
    );
  }
}

// export default class DisplayAdsLargeBanner extends Component {
//   render() {
//     return <div></div>;
//   }
// }
