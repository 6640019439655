import React, { Component } from "react";

export default class GoogleAd extends Component {
  // This code is ran when the component mounts
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  // an outer div for styling purposes
  // changed class to ClassName
  // changed style from string to an object

  render() {
    return (
      <div>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client={this.props.client}
          data-ad-slot={this.props.slot}
          data-ad-format={this.props.format}
          data-full-width-responsive="true"
        ></ins>
      </div>
    );
  }
}

//   render() {
//     return <div></div>;
//   }
// }
